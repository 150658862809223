import {
  useLiquidityHub as useLH,
  SwapConfirmation,
  LiquidityHubProvider,
  zeroAddress,
  PoweredByOrbs,
  useSettings,
  WEBSITE_URL,
  useSwapButtonContent,
  getSwapModalTitle,
} from '@orbs-network/liquidity-hub-ui-sdk'
import { useContext, useEffect, useMemo, useState } from 'react'
import { useConfig, useNetwork } from 'wagmi'
import useWalletModal from '../../../hooks/useWalletModal'
import { useWeb3Wagmi } from '../../../hooks/useWeb3'
import { useCurrency } from '../../../hooks/v3/Tokens'
import { tryParseAmount } from '../../../v3lib/utils/utils'
import BN from 'bignumber.js'
import Modal from '../../common/Modal'
import StyledButton from '../../common/Buttons/styledButton'
import styled from 'styled-components'
import Toggle from '../../common/Toggle'
import { formatAmount, fromWei } from '../../../utils/formatNumber'
import { BaseAssetsConetext } from '../../../context/BaseAssetsConetext'
import { useCallback } from 'react'

export const Provider = ({ children }) => {
  const config = useConfig()
  const { account } = useWeb3Wagmi()
  const { chain } = useNetwork()
  const { openWalletModal } = useWalletModal()

  return (
    <LiquidityHubProvider
      swap={{ maxFailures: 2 }}
      chainId={chain?.id}
      provider={config.data?.provider}
      account={account}
      connectWallet={openWalletModal}
      partner='lynex'
    >
      {children}
    </LiquidityHubProvider>
  )
}

const useLiquidityHub = (fromAsset, toAsset, fromAmount, dexAmountOut, slippage) => {
  const inCurrency = useCurrency(fromAsset ? fromAsset.address : undefined)

  const parsedFromAmount = useMemo(() => tryParseAmount(fromAmount, inCurrency)?.quotient.toString(), [fromAmount, inCurrency])
  const minAmountOut = useDexMinAmountOut(dexAmountOut, slippage)
  const persistedMinAmountOut = useDexPersistedAmountOut(fromAsset, toAsset, fromAmount, minAmountOut)
  const tokens = useMemo(() => {
    return {
      fromAsset: {
        address: fromAsset?.address === 'ETH' ? zeroAddress : fromAsset?.address,
        decimals: fromAsset?.decimals,
        logoUrl: fromAsset?.logoURI,
        symbol: fromAsset?.symbol,
        name: fromAsset?.name,
      },
      toAsset: {
        address: toAsset?.address === 'ETH' ? zeroAddress : toAsset?.address,
        decimals: toAsset?.decimals,
        logoUrl: toAsset?.logoURI,
        symbol: toAsset?.symbol,
        name: toAsset?.name,
      },
    }
  }, [fromAsset, toAsset])

  return useLH({
    fromAmount: BN(persistedMinAmountOut || 0).gt(0) ? parsedFromAmount : undefined,
    fromToken: tokens.fromAsset,
    toToken: tokens.toAsset,
    minAmountOut: persistedMinAmountOut,
    slippage,
    approveExactAmount: true,
  })
}

const useDexPersistedAmountOut = (fromAsset, toAsset, fromAmount, outAmount) => {
  const [value, setValue] = useState(outAmount)

  useEffect(() => {
    if (!fromAsset || !toAsset || !fromAmount) {
      setValue(undefined)
    }
    return () => {
      setValue(undefined)
    }
  }, [fromAsset?.address, toAsset?.address, fromAmount])

  useEffect(() => {
    if (!outAmount) return
    setValue(outAmount)
  }, [outAmount, setValue])
  return value
}

export const ConfirmationModal = ({ liquidityHubPayload, fromAsset, toAsset, setFromAsset, setFromAmount }) => {
  const baseAssets = useContext(BaseAssetsConetext)
  const { fromToken, submitSwap, swapStatus, swapSuccess, closeConfirmationModal, swapLoading, swapError, isWrapped, showConfirmationModal, fromAmount } =
    liquidityHubPayload
  const [exactOutAmount, setExactOutAmount] = useState('')
  const [acceptedQuote, setAcceptedQuote] = useState(undefined)

  const onSubmit = useCallback(async () => {
    try {
      setAcceptedQuote(liquidityHubPayload.quote)
      const result = await submitSwap()

      if (result.exactOutAmount) {
        setExactOutAmount(result.exactOutAmount)
      }
    } catch (error) {
      console.error('Error submitting swap', error)
    }
  }, [submitSwap, toAsset, setExactOutAmount, liquidityHubPayload.quote])
  const onClose = useCallback(() => {
    closeConfirmationModal()
    if (swapLoading) return
    if (swapSuccess) setFromAmount('')
    // in case of swap error, we switch ETH to WETH, so the user will see his funds, since the wrap was successful
    if (isWrapped && swapError) {
      setFromAsset(baseAssets.find((asset) => asset.symbol === 'WETH'))
    }
    setAcceptedQuote(undefined)
    setExactOutAmount('')
  }, [setFromAsset, baseAssets, isWrapped, swapLoading, closeConfirmationModal, swapError, swapSuccess])

  const quote = acceptedQuote || liquidityHubPayload.quote

  const outAmountUi = useMemo(() => {
    if (!quote || !toAsset) return ''
    return fromWei(exactOutAmount || quote.outAmountWS || quote.outAmount, toAsset.decimals).toString(10)
  }, [quote, toAsset, exactOutAmount])

  const fromAmountUi = useMemo(() => {
    if (!fromAsset) return ''
    return fromWei(fromAmount, fromAsset.decimals).toString(10)
  }, [fromAmount, fromAsset])

  const fromAssetPrice = fromAsset?.price
  const toAssetPrice = toAsset?.price
  const title = useMemo(() => {
    return getSwapModalTitle(swapStatus)
  }, [swapStatus])

  const buttonText = useSwapButtonContent(fromToken?.address, fromAmount)
  const fromUsd = useMemo(() => formatAmount((fromAssetPrice || 0) * (Number(fromAmountUi) || 0)), [fromAssetPrice, fromAmountUi])
  const toUsd = useMemo(() => formatAmount((toAssetPrice || 0) * (Number(outAmountUi) || 0)), [toAssetPrice, outAmountUi])
  return (
    <Modal popup={showConfirmationModal} disableOutside={true} setPopup={onClose} title={title} isToken={false}>
      <StyledModalContainer className='w-full'>
        <SwapConfirmation fromTokenUsd={`$${fromUsd}`} toTokenUsd={`$${toUsd}`} {...liquidityHubPayload} outAmount={formatAmount(outAmountUi, undefined, 5)}>
          <SwapConfirmation.Main
            SubmitButton={
              <BottomContent>
                <GasFee toAsset={toAsset} gasFee={quote?.gasAmountOut} />
                <StyledSubmitSwapButton onClickHandler={onSubmit} pending={swapLoading} content={buttonText} />
                <GasslessText>Gassless transaction powered by Orbs</GasslessText>
              </BottomContent>
            }
          />
        </SwapConfirmation>
      </StyledModalContainer>
    </Modal>
  )
}

const GasslessText = styled('p')({
  fontSize: 14,
  color: 'rgb(218, 218, 218)',
  opacity: 0.8,
})

const BottomContent = styled('div')({
  marginTop: 10,
  display: 'flex',
  flexDirection: 'column',
  gap: 15,
  width: '100%',
})

const GasFee = ({ toAsset, gasFee }) => {
  const feeUi = useMemo(() => {
    if (!toAsset || !gasFee) return ''
    return fromWei(gasFee, toAsset?.decimals).toString(10)
  }, [toAsset, gasFee])

  const feeUsd = useMemo(() => {
    if (!toAsset || !feeUi) return ''
    return formatAmount((toAsset?.price || 0) * (Number(feeUi) || 0))
  }, [toAsset, feeUi])

  return (
    <StyledGasFee>
      <p>Network cost</p>
      <p>${feeUsd}</p>
    </StyledGasFee>
  )
}

const StyledGasFee = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%',
  color: 'rgb(218, 218, 218)',
  opacity: 0.8,
  fontSize: '14px',
})

const useIsLHSwap = (lhMinAmountOut, dexAmountOut, slippage, lhDisabled) => {
  const dexMinAmountOut = useDexMinAmountOut(dexAmountOut, slippage)
  const isLhPriceBetter = useMemo(() => BN(lhMinAmountOut || 0).gt(dexMinAmountOut || 0), [lhMinAmountOut, dexMinAmountOut])
  if (lhDisabled || BN(dexAmountOut).isZero()) return false

  return isLhPriceBetter
}

const useDexMinAmountOut = (dexMinAmountOut, slippage) => {
  return useMemo(() => {
    if (!dexMinAmountOut) return undefined
    if (!slippage) return dexMinAmountOut
    return BN(dexMinAmountOut)
      .times(100 - slippage)
      .div(100)
      .decimalPlaces(0)
      .toFixed()
  }, [dexMinAmountOut, slippage])
}

const Routing = ({ token }) => {
  return (
    <div
      className={`relative flex py-4 px-4 after:w-[60px] before:absolute before:left-0 before:top-0 before:w-full before:h-[48px] before:rounded-b-[24px] before:border-dashed before:border-[#DEDBF2] before:border-b before:border-x`}
    >
      <div className='py-2 px-3 rounded-xl bg-[#3d3d3d] border border-[#ffffff33] relative text-white text-base w-fit h-fit mt-[10px]'>100%</div>
      <div className='relative flex grow px-3'>
        <div className='w-full flex justify-between overflow-hidden space-x-4 before:content-[""] after:content-[""]'>
          <div className='px-3 py-2 bg-[#3d3d3d] border border-[#ffffff33] rounded-xl w-fit space-y-1'>
            <div className='flex items-center justify-around py-[7px] space-x-[6px]'>
              {token.logoURI ? (
                <img src={token?.logoURI} alt='' className='w-[22px] h-[22px]' />
              ) : (
                <span className='text-base md:text-[18px] text-white'>{token?.symbol}</span>
              )}
            </div>
            <div className='text-[12px] md:text-[14px] text-white pl-1 space-x-1'>
              <span>Liquidity Hub</span>
              <span>100%</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const Settings = () => {
  const { liquidityHubEnabled, updateLiquidityHubEnabled } = useSettings()
  return (
    <>
      <div className='flex items-center justify-between w-full mt-4'>
        <div className='flex items-center space-x-1.5'>
          <p className='text-sm font-medium text-white'>Disable Liquidity Hub</p>
        </div>
        <Toggle checked={!liquidityHubEnabled} onChange={() => updateLiquidityHubEnabled(!liquidityHubEnabled)} toggleId='liquifity-hub-toggle' />
      </div>
      <StyledSettingsText className='text-sm text-white'>
        <a href={`${WEBSITE_URL}/liquidity-hub`} rel='noreferrer' target='_blank'>
          {' '}
          Liquidity Hub
        </a>
        , powered by{' '}
        <a href={WEBSITE_URL} rel='noreferrer' target='_blank'>
          Orbs
        </a>
        , may provide better price by aggregating liquidity from multiple sources.{' '}
        <a href={`${WEBSITE_URL}/liquidity-hub`} rel='noreferrer' target='_blank'>
          For more info.
        </a>
      </StyledSettingsText>
    </>
  )
}

const StyledSettingsText = styled.p`
  a {
    text-decoration: underline;
  }
`

const StyledPoweredByOrbs = styled(PoweredByOrbs)`
  margin-top: 20px;
  font-size: 18px;
  img {
    min-width: 24px;
    min-height: 24px;
  }
`

const StyledModalContainer = styled.div`
  padding-top: 20px;
  .lh-step-loader {
    background-color: #3D3B3A;
  }
  a {
    color: rgb(223 131 47/1);
    text-decoration: none;
  }
  .lh-powered-by {
    color: #fff;
  }
  
  .lh-summary-separator {
    &::before {
      background-color: rgba(255, 255, 255, 0.2);
    }
}
    .lh-steps {
      border-top: 1px solid rgba(255, 255, 255, 0.2);
    }
  }
`

const StyledSubmitSwapButton = styled(StyledButton)`
  padding: 15px 0px;
  width: 100%;
`

export const LiquidityHub = {
  Provider,
  ConfirmationModal,
  useIsLHSwap,
  PoweredByOrbs: StyledPoweredByOrbs,
  Routing,
  Settings,
  useLiquidityHub,
}
