import React, { useContext, useMemo, useState } from 'react'
import BigNumber from 'bignumber.js'
import TabFilter from '../../components/common/TabFilter'
import SearchInput from '../../components/common/Input/SearchInput'
import Table from '../../components/pages/vote/table'
import Timer from '../../components/common/Timer'
import MobileFilterModal from '../../components/common/MobileFilterModal'
import { formatAmount, getLPSymbol, ZERO_ADDRESS } from '../../utils/formatNumber'
// import VeTHESelect from '../../components/common/VeTHESelect'
import Toggle from '../../components/common/Toggle'
import { useEpochTimer, useVoteEmissions, epoch0, epochDuration } from '../../hooks/useGeneral'
import { fetchTotalVotingPower } from '../../utils/fetchTotalVotingPower'
import { PoolTypes } from '../../config/constants'
import { veTHEsContext } from '../../context/veTHEsConetext'
import usePrices from '../../hooks/usePrices'
import { FusionsContext } from '../../context/FusionsContext'
import WarningTag from '../../components/common/WarningTag'
import { STABLE_TOKENS } from '../../config/constants'
const getCurrentEpochStartTime = () => {
  const curTime = Math.floor(Date.now() / 1000)
  const currentEpoch = Math.floor((curTime - epoch0) / epochDuration)
  return epoch0 + currentEpoch * epochDuration
}

const sortOptions = [
  {
    label: 'APR',
    value: 'apr',
    isDesc: true,
  },
  {
    label: 'Total Votes',
    value: 'votes',
    isDesc: true,
  },
  {
    label: 'Rewards',
    value: 'rewards',
    isDesc: true,
  },
  {
    label: 'Your Vote',
    value: 'your',
    isDesc: true,
  },
]

const Vote = () => {
  const [filter, setFilter] = useState(PoolTypes.ALL)
  const [sort, setSort] = useState({})
  const [mobileFilter, setMobileFilter] = useState(false)
  const [isVoted, setIsVoted] = useState(false)
  const [memeOnly, setMemeOnly] = useState(false)
  const [searchText, setSearchText] = useState('')
  const [percent, setPercent] = useState({})
  const [epochTotalVotes, setEpochTotalVotes] = useState(new BigNumber(0))
  const [votesCasted, setVotesCasted] = useState(new BigNumber(0))
  const fusions = useContext(FusionsContext)
  const { accountInfo } = useContext(veTHEsContext)
  const { voteEmssions } = useVoteEmissions()
  const { days, hours, mins, epoch } = useEpochTimer()
  const prices = usePrices()
  const thisWeekVotingPower = accountInfo?.epochVotes
  const nextWeekVotingPower = accountInfo?.nextEpochVotes
  const currentEpochStartTime = getCurrentEpochStartTime()

  useMemo(() => {
    const fetchVotes = async () => {
      try {
        const { totalVotingPower } = await fetchTotalVotingPower(currentEpochStartTime)
        setEpochTotalVotes(new BigNumber(totalVotingPower))
      } catch (error) {
        console.error('Error fetching total voting power:', error)
      }
    }
    fetchVotes()
  }, [currentEpochStartTime])

  useMemo(() => {
    if (fusions && fusions.length > 0) {
      const totalVotesCasted = fusions.reduce((sum, cur) => {
        return sum.plus(cur.gauge.weight)
      }, new BigNumber(0))
      setVotesCasted(totalVotesCasted)
    }
  }, [fusions])

  const castedVotesPercentage = useMemo(() => {
    return epochTotalVotes.isZero() ? 0 : votesCasted.div(epochTotalVotes).times(100)
  }, [votesCasted, epochTotalVotes])

  const avgApr = useMemo(() => {
    if (fusions && fusions.length > 0 && prices) {
      const totalBribe = fusions.reduce((sum, cur) => {
        return sum.plus(cur.gauge.bribeUsd)
      }, new BigNumber(0))
      const totalWeight = fusions.reduce((sum, cur) => {
        return sum.plus(cur.gauge.weight)
      }, new BigNumber(0))
      const totalVoteUsd = totalWeight.times(prices['LYNX'])
      return totalVoteUsd.isZero() ? 0 : totalBribe.times(52).div(totalVoteUsd).times(100)
    }
    return new BigNumber(0)
  }, [fusions, prices])

  const totalInfo = useMemo(() => {
    return [
      {
        title: 'Available votes',
        balance: thisWeekVotingPower ? formatAmount(thisWeekVotingPower) : '-',
      },
      {
        title: 'Next week votes',
        balance: nextWeekVotingPower ? formatAmount(nextWeekVotingPower) : '-',
      },
      {
        title: 'Emissions / % of Vote',
        balance: '$' + formatAmount(voteEmssions),
      },
      {
        title: 'Average Voting APR',
        balance: formatAmount(avgApr) + '%',
      },
      {
        title: 'Total Votes',
        balance: formatAmount(epochTotalVotes),
      },
      {
        title: `Epoch ${epoch} Ends In`,
        balance: `${days}d ${hours}h ${mins}m`,
      },
    ]
  }, [accountInfo, voteEmssions, avgApr, mins, epochTotalVotes])

  const pools = useMemo(() => {
    let filteredPools = fusions
      .filter((pair) => pair.gauge.address !== ZERO_ADDRESS && pair.isValid)
      .map((pair) => {
        let votes = {
          weight: new BigNumber(0),
          weightPercent: new BigNumber(0),
        }
        if (accountInfo && accountInfo.votes.length > 0) {
          const found = accountInfo.votes.find((ele) => ele.address.toLowerCase() === pair.address.toLowerCase())
          if (found) {
            votes = found
          }
        }
        return {
          ...pair,
          votes,
        }
      })

    // Updated stable pool filtering
    if (filter === PoolTypes.STABLE) {
      filteredPools = filteredPools.filter((item) => {
        const token0Address = item.token0?.address?.toLowerCase()
        const token1Address = item.token1?.address?.toLowerCase()
        const stableTokenAddresses = Object.values(STABLE_TOKENS).map((address) => address.toLowerCase())

        return (
          typeof STABLE_TOKENS === 'object' &&
          STABLE_TOKENS !== null &&
          stableTokenAddresses.includes(token0Address) &&
          stableTokenAddresses.includes(token1Address)
        )
      })
    }

    return filteredPools
  }, [fusions, accountInfo, filter])

  const votedGauges = useMemo(() => {
    const temp = []
    for (let i = 0; i < Object.keys(percent).length; i++) {
      const key = Object.keys(percent)[i]
      if (!isNaN(Number(percent[key])) && Number(percent[key]) !== 0) {
        const found = pools.find((pool) => pool.address === key)
        temp.push({
          ...found,
          votes: percent[key],
        })
      }
    }
    return temp
  }, [pools, percent])

  const totalPercent = useMemo(() => {
    return Object.values(percent).reduce((sum, current) => {
      return sum + (!current || current === '' ? 0 : Number(current))
    }, 0)
  }, [percent])

  return (
    <>
      <div className='max-w-[1200px] mt-10 mx-auto'>
        <div className='lg:flex items-start justify-between lg:space-x-2 xl:space-x-[20px]'>
          <div className='w-full lg:w-[30%] xl:w-1/3'>
            <div className='max-w-[450px]'>
              <p className='text-[#b8b6cb] text-sm md:text-base leading-[22px] md:leading-6 mt-1'>
                Select your veLYNX and use 100% of your votes for one or more pools to earn bribes and trading fees. {''}
                <a href='https://lynex.gitbook.io/lynex/' target='_blank' rel='noreferrer'>
                  <span className='text-sm md:text-base whitespace-nowrap text-themeOrange'>Learn More</span>
                </a>
              </p>
            </div>
          </div>
          <div className='w-full lg:w-[72%] xl:w-[85%] mt-4 lg:mt-0'>
            <Timer arr={totalInfo} className='w-full' />
            <div className='w-full relative h-10 items-center flex justify-center'>
              <div className='pool-bar' />
              <div className='pool-completion-bar' style={{ width: `${formatAmount(castedVotesPercentage)}%` }} />
              <div className='percentage'>{formatAmount(castedVotesPercentage)}% Votes Casted</div>
            </div>
          </div>
        </div>
        <div className='flex flex-col lg:flex-row items-center justify-between w-full mt-4 lg:mt-[23px] lg:space-x-7 xl:space-x-[60px] relative'>
          <div className='w-full lg:w-[55%] xl:w-1/2 lg:flex-row flex flex-col-reverse lg:items-center lg:space-x-5'>
            <div className='flex items-center w-full mt-3.5 lg:mt-0'>
              <SearchInput className={''} searchText={searchText} setSearchText={setSearchText} placeholder='Search LP' />
              {/* filter button for mobile */}
              <button
                onClick={() => {
                  setMobileFilter(!mobileFilter)
                }}
                className='w-12 flex-shrink-0 h-[42px] lg:hidden ml-3'
              >
                <img alt='' className='w-12 h-[42px]' src='/images/liquidity/filter.svg' />
              </button>
            </div>
            {/* <VeTHESelect className={'lg:max-w-[300px] w-full'} isSelected={veTHE} setIsSelected={setVeTHE} /> */}
          </div>
          <WarningTag text={'All numbers are merely projections and can change at any time relative to fees, bribes and voter behaviour'} />
        </div>

        <div className='flex items-center justify-between w-full mt-4 lg:mt-[23px] lg:space-x-7 xl:space-x-[60px] relative'>
          {/* for desktop */}
          <div className='w-full hidden lg:flex items-center space-x-8'>
            <TabFilter data={Object.values(PoolTypes)} filter={filter} setFilter={setFilter} />
            <div className='flex items-center space-x-2'>
              <Toggle checked={isVoted} onChange={() => setIsVoted(!isVoted)} toggleId='isVoted' />
              <p className='text-[#DEDBF2] text-sm xl:text-[17px] whitespace-nowrap'>Voted Only</p>
            </div>
            <div className='flex items-center space-x-2'>
              <Toggle checked={memeOnly} onChange={() => setMemeOnly(!memeOnly)} toggleId='memeOnly' />
              <p className='text-[#DEDBF2] text-sm xl:text-[17px] whitespace-nowrap'>Meme Carnival Only</p>
            </div>
          </div>
          {/* mobile filters popup */}
          {mobileFilter && (
            <MobileFilterModal
              setMobileFilter={setMobileFilter}
              setFilter={setFilter}
              filter={filter}
              tabs={Object.values(PoolTypes)}
              isVote={true}
              isVoted={isVoted}
              setIsVoted={setIsVoted}
              memeOnly={memeOnly}
              setMemeOnly={setMemeOnly}
              sort={sort}
              setSort={setSort}
              sortOptions={sortOptions}
            />
          )}
        </div>
        <div className='my-4'>
          {votedGauges.length > 0 && (
            <>
              <p className='text-white f-f-fg'>Your Votes:</p>
              <div className='flex overflow-auto mb-4 lg:mb-0 pb-2 lg:pb-0  w-full mt-1.5 lg:-mt-2.5 space-x-4 lg:space-x-8'>
                {votedGauges.map((pool, idx) => {
                  return (
                    <div key={idx} className='flex lg:my-4 flex-shrink-0 max-w-[280px] rounded-md border border-themeOrange'>
                      <div className={`flex items-center w-3/4 rounded-tl-md rounded-bl-md border-r border-themeOrange`}>
                        <button
                          className='border-r border-themeOrange md:w-10 md:h-10'
                          onClick={() => {
                            setPercent({
                              ...percent,
                              [pool.address]: '',
                            })
                          }}
                        >
                          <img alt='' src='/images/vote/remove-button.svg' />
                        </button>
                        <div className='flex items-center w-full space-x-2 ml-1.5 py-1.5'>
                          <div className='flex items-center  -space-x-2'>
                            <img className='relative w-6 h-6 z-10' alt='' src={pool.token0.logoURI} />
                            <img className='relative w-6 h-6 z-[5]' alt='' src={pool.token1.logoURI} />
                          </div>
                          <div className=' text-white'>
                            <p className='font-semibold f-f-fg lg:text-base text-[14px] leading-5'>{getLPSymbol(pool)}</p>
                            <p className='tracking-[0.66px] text-[9px] leading-none'>{pool.title}</p>
                          </div>
                        </div>
                      </div>
                      <div className='relative w-1/5 lg:w-[28%]'>
                        <input
                          onChange={(e) => {
                            const val = isNaN(Number(percent[pool.address])) ? 0 : Number(percent[pool.address])
                            const newVal = isNaN(Number(e.target.value)) || Number(e.target.value) < 0 ? 0 : Math.floor(Number(e.target.value))
                            const maxValue = 100 - totalPercent + val === 0 ? '' : 100 - totalPercent + val
                            let final = newVal === 0 ? '' : totalPercent - val + newVal > 100 ? maxValue : newVal
                            setPercent({
                              ...percent,
                              [pool.address]: !e.target.value ? '' : final,
                            })
                          }}
                          type={'number'}
                          className='py-3 w-[90%] pl-3 text-white font-medium text-lg lg:text-xl bg-transparent'
                          value={pool.votes}
                        />
                        <span className='text-white font-medium text-lg lg:text-xl absolute right-0 z-10 mt-3 -mr-1.5 lg:mr-1.5'>%</span>
                      </div>
                    </div>
                  )
                })}
              </div>
            </>
          )}
        </div>
        <Table
          pools={pools}
          sort={sort}
          setSort={setSort}
          sortOptions={sortOptions}
          filter={filter}
          searchText={searchText}
          isVoted={isVoted}
          accountInfo={accountInfo}
          percent={percent}
          setPercent={setPercent}
          totalPercent={totalPercent}
          memeOnly={memeOnly}
        />
      </div>
    </>
  )
}

export default Vote
