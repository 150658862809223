import React from 'react'
import { TabSizes } from '../../../config/constants'

const TabFilter = ({ data, filter, setFilter, className, size = TabSizes.MEDIUM, wfull }) => {
  return (
    <div
      className={`flex overflow-x-auto no-scrollbar items-center space-x-2 ${wfull ? 'w-full' : 'sm:w-full xl:w-fit'} rounded-[12px] bg-white/5 shadow ${
        className ? ' ' + className : ''
      }`}
    >
      {data.map((item, idx) => {
        return (
          <div
            onClick={() => setFilter(item)}
            key={`tab-${idx}`}
            className={`cursor-pointer flex-grow whitespace-nowrap ${
              size === TabSizes.LARGE ? 'px-4' : size === TabSizes.MEDIUM ? 'px-3' : 'px-2'
            } h-[34px] md:h-10 rounded-[10px] flex items-center justify-center uppercase transition-all ${
              filter === item ? 'bg-themeOrange text-white font-medium border border-themeOrange' : 'text-dimGray border border-transparent'
            } text-[13px] lg:text-sm xl:text-[15px] f-f-fg`}
          >
            {item}
          </div>
        )
      })}
    </div>
  )
}

export default TabFilter
