import React from 'react'
import OutsideClickHandler from 'react-outside-click-handler'
import { LiquidityHub } from '../../pages/swap/LiquidityHub'
import Toggle from '../Toggle'

const Index = ({ slippage, setSlippage, deadline, setDeadline, multihops, isSwap, setMultihops, setSetting, updateSwapType, swapType }) => {
  const slipageTolerance = ['0.05', '0.1', '0.5', '1.00']

  return (
    <div className='fixed top-0 left-0 flex items-center justify-center visible z-[202] opacity-100 w-screen h-screen bg-opacity-[0.4] backdrop-blur-[6px] bg-black'>
      <OutsideClickHandler
        onOutsideClick={() => {
          setSetting(false)
        }}
      >
        <div className='w-full max-w-[400px] space-y-3 z-50 p-6 bg-white/10 backdrop-blur-2xl border border-[#ffffff33] rounded-[20px] m-auto'>
          <p className='text-white font-semibold text-base md:text-xl'>TRANSACTION SETTINGS</p>
          <p className='text-base font-medium text-white'>Slippage Tolerance</p>
          <div className='md:flex items-center w-full'>
            <div className='flex items-center space-x-2 z-10 w-full'>
              {slipageTolerance.map((item, idx) => {
                return (
                  <button
                    key={idx}
                    onClick={() => {
                      setSlippage(Number(item))
                    }}
                    className={`
                max-w-[60px] flex-shrink-0 w-full rounded-md flex flex-col items-center text-white justify-center h-8 cursor-pointer
                ${slippage == Number(item) ? 'bg-themeOrange' : 'bg-white/5'}`}
                  >
                    {item}%
                  </button>
                )
              })}
            </div>
          </div>
          <div className='flex items-center space-x-[9px] w-full mt-[11px] md:mt-0 md:justify-start'>
            <input
              className='placeholder-themeBlack text-black max-w-[240px] bg-body w-full h-[48px] rounded-full px-2 text-lg block focus-visible:!outline-none'
              value={slippage}
              onChange={(e) => setSlippage(e.target.value || 0)}
              type={'range'}
              step={0.05}
              min={0.05}
              max={20}
            />
            <span className='text-lg text-white w-8'>{slippage}%</span>
          </div>
          <div>
            <div className={`${slippage < 0.5 || slippage > 5 ? 'visible' : 'invisible'} flex w-full mt-[-12px] h-6 text-warn`}>
              {slippage > 5 ? 'Your transaction may be frontrun' : 'Your transaction may fail'}
            </div>
          </div>

          <div className='flex items-center space-x-1.5'>
            <p className='text-base font-medium text-white'>Transaction Deadline</p>
          </div>
          <>
            <div className='flex max-w-[180px] items-center justify-start mt-2 rounded-md bg-white/5'>
              <input
                className='bg-transparent placeholder-black max-w-[80px] h-10 md:h-[42px] w-full text-white pl-5 pr-2 text-lg block focus-visible:!outline-none'
                type={'number'}
                value={deadline}
                onChange={(e) => setDeadline(e.target.value || 0)}
                min={0}
              />
              <span className='text-base md:text-lg text-white w-auto'>minutes</span>
            </div>
          </>
          {isSwap && (
            <>
              <div className='flex items-center justify-between w-full mt-4'>
                <div className='flex items-center space-x-1.5'>
                  <p className='text-sm font-medium text-white'>Only Classic Liquidity</p>
                </div>
                <Toggle
                  checked={swapType === 'CLASSIC'}
                  onChange={() => (swapType !== 'CLASSIC' ? updateSwapType('CLASSIC') : updateSwapType('SWAP'))}
                  toggleId='v1'
                />
              </div>
              <div className='flex items-center justify-between w-full mt-4'>
                <div className='flex items-center space-x-1.5'>
                  <p className='text-sm font-medium text-white'>Only Concentrated Liquidity</p>
                </div>
                <Toggle
                  checked={swapType === 'FUSION'}
                  onChange={() => (swapType !== 'FUSION' ? updateSwapType('FUSION') : updateSwapType('SWAP'))}
                  toggleId='fusion'
                />
              </div>

              {setMultihops && (
                <>
                  <div className='flex items-center justify-between w-full mt-4'>
                    <div className='flex items-center space-x-1.5'>
                      <p className='text-base font-medium text-white'>Disable Multihops</p>
                    </div>
                    <Toggle checked={!multihops} onChange={() => setMultihops(!multihops)} toggleId='multihops' />
                  </div>
                  <p className='text-sm text-white'>Enable or disable aggregating liquidity from multiple pools.</p>
                </>
              )}
              <LiquidityHub.Settings />
            </>
          )}
        </div>
      </OutsideClickHandler>
    </div>
  )
}

export default Index
